import React from 'react'
import { Loader } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

function abrirCurriculo() {
    window.location.replace('https://drive.google.com/file/d/153twgf1zMK8ijFwfvX8_Ll7r6zjfvpJo/view?usp=sharing')
}

function Curriculo() {
  return (
    <div>
    <Loader inverse center content="Abrindo" onLoad={abrirCurriculo()} />
  </div>
  );
}

export default Curriculo;

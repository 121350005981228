import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className="footer">
       <span>&copy; 2023 - Rúben Filipe</span> 
    </div>
  );
}

export default Footer;
